<template>
<div>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
    >
      {{ snackmsg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
  <v-card>
    <v-card-title>Award Nomination</v-card-title>
    <v-card-text>
      <v-form v-model="valid" v-if="!loading">
        <v-container>
          <v-row>
            <v-col>
            <v-text-field
              v-model="nomination.nominee_first_name"
              label="First Name"
              :rules="noEmptyField"
              required
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <v-text-field
              v-model="nomination.nominee_last_name"
              label="Last Name"
              :rules="noEmptyField"
              required
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <v-text-field
              v-model="nomination.nominee_member_id"
              label="RMN ID"
              :rules="rules.rmnId"
              hint="Example: RMN-9999-99"
              required
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <v-select
              :items="components"
              v-model="nomination.nominee_branch"
              item-text="name"
              item-value="abbr"
              label="Branch"
              @change="branchChange"
            ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="awards"
                label="Award"
                item-text="name"
                item-value="id"
                v-model="nomination.award"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="myQueues"
                label="Nominating on behalf of"
                item-text="name"
                item-value="id"
                v-model="nomination.outq"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                name="award_text"
                label="Nomination Text"
                :rules="noEmptyField"
                v-model="nomination.nomination_text"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-center">
            <v-btn
              :disabled="!valid"
              elevation="2"
              @click="submitDone"
            >
              Submit
            </v-btn>
            <v-btn
              :disabled="!valid"
              elevation="2"
              @click="submitAddAnother"
              class="mx-2"
            >
              Submit and Add Another
            </v-btn>
            <v-btn
              elevation="2"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Nomination',
  data: () => {
    return {
      valid: false,
      loading: false,
      snackbar: false,
      snackmsg: '',
			awardList: [],
      awardBranch: 'RMN',
      rules: {
        rmnId: [v => /RMN-\d{4}-\d{2}/.test(v) || "Required format: RMN-9999-99"]
      },
      components: [
        { abbr: 'RMN', name: 'Royal Manticoran Navy' },
        { abbr: 'RMMC', name: 'Royal Manticoran Marine Corps' },
        { abbr: 'RMA', name: 'Royal Manticoran Army' },
        { abbr: 'GSN', name: 'Grayson Space Navy' },
        { abbr: 'IAN', name: 'Imperial Andermani Navy' },
        { abbr: 'RHN', name: 'Republic of Haven Navy' },
        { abbr: 'RMACS', name: 'Royal Manticoran Astro-Control Service' },
        { abbr: 'RMMM', name: 'Royal Manticoran Merchant Marine' },
        { abbr: 'SFC', name: 'Sphinx Forestry Commission' },
        { abbr: 'DIP', name: 'Civilian Diplomaatic Corps' },
        { abbr: 'SIS', name: 'Civilian Intelligence Corps' }
      ],
      noEmptyField: [
        v => !!v || "This field must be filled"
      ],
      nomination: {
        nominator_id: '',
        nominee_first_name: '',
        nominee_last_name: '',
        nominee_member_id: '',
        nominee_branch: 'RMN',
        award: '',
        outq: 0,
        queue: 1,
        nomination_text: ''
      }
    }
  },
  mounted () {
    this.$store.dispatch('precedence/find', {paginate: false, query: {}});
    this.$store.dispatch('queue-assignments/find', {});
    this.$store.dispatch('queues/find', {});
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/user',
      precedenceList: 'precedence/list',
      awardsList: 'awards/list',
      queueAssignmentsList: 'queue-assignments/list',
      queuesList: 'queues/list'
    }),
		awards() {
      const { Precedence } = this.$FeathersVuex.api;
			let precList = Precedence.findInStore({query: {branch: this.awardBranch}}).data;
      let awards = this.awardsList;
      return precList.map(p => {
        let a = awards.find(a => a.id === p.award);
        return {id: a.id, name: a.name, image_url: a.image_url, precedence: p.precedence, award: p.award}
      }).sort((a, b) => {return a.precedence-b.precedence});
		},
    allAwards() {
			const { Award } = this.$FeathersVuex.api;
			return Award.findInStore({query: {}}).data;
		},
    myQueues() {
      let queueIdList = this.queueAssignmentsList
        .filter(q => q.member === this.authUser.id)
        .map(q => {
          return q.queue;
        }) || [];
      
      let assigned = this.queuesList.filter(q => queueIdList.indexOf(q.id) > -1) || [];
      return [{id: 0, name: 'Myself'}].concat(assigned);
    }
  },
  methods: {
    feedbackSnack(msg) {
      const vm = this;
      this.snackmsg = msg;
      this.snackbar = true;
      setTimeout(() => { vm.snackbar = false; }, 5000)
    },
    branchChange () {
      let branch = this.nomination.nominee_branch;
      if (['RMMC', 'IAN', 'RHN'].indexOf(branch) > -1) {
        this.awardBranch = "RMN";
        return;
      }
      this.awardBranch = this.nomination.nominee_branch;
    },
    submitDone () {
      let vm = this;
      this.nominate()
      .then(() => {
          this.feedbackSnack("Nomination submitted successfully.")
          setTimeout(() => { vm.$router.push('/dashboard'); }, 1000)
        })
        .catch((err) => {
          this.feedbackSnack("The nomination ccould not be saved.");
          console.error(err);
        })
      
    },
    submitAddAnother () {
      this.nominate();
      this.reset();
    },
    nominate () {
      this.nomination.nominator_id = this.authUser.id;
      // this.nomination.nomination_text = this.nomination.nomination_text.replace(/\r\n|\n/, '<br />');
      let test = new this.$FeathersVuex.api.Nomination({...this.nomination})
      return test.save()
    },
    reset () {
      this.nomination = {
        nominator_id: '',
        nominee_first_name: '',
        nominee_last_name: '',
        nominee_member_id: '',
        nominee_branch: 'RMN',
        award: '',
        outq: 0,
        queue: 1,
        nomination_text: ''
      };
    },
    cancel () {
      this.reset();
      this.$router.push('/dashboard');
    }
  }
}
</script>
